<script>
    import Router from "svelte-navigator/src/Router.svelte";
    import Route from "svelte-navigator/src/Route.svelte";
    import Header from './components/header.svelte'
    import NavMenu from './components/navMenu.svelte'
    import Home from './views/home.svelte'
    import AnimeList from "./views/animeList.svelte";
    import AnimePage from "./views/animePage.svelte";
    import { useParams } from "svelte-navigator";
</script>

<h1>Soon</h1>

<style>
    body {
        font-family: Arial, sans-serif;
        margin: 0;
        padding: 0;
        background-color: #333; /* ciemne tło */
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
    h1 {
        color: #fff; /* biały kolor tekstu */
        font-size: 3em;
    }
</style>