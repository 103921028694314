import {
  b
} from "./chunk.TL6KDKGI.js";

// node_modules/lit-html/directives/if-defined.js
var l = (l2) => null != l2 ? l2 : b;

export {
  l
};
/**
 * @license
 * Copyright 2018 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
